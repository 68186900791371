import React from "react";
import smartPhone from "../../assets/img/icons/lineal/smartphone.svg";
import web from "../../assets/img/icons/lineal/browser.svg";
import web2 from "../../assets/img/icons/lineal/browser-2.svg";
import mvp from "../../assets/img/mvp (2).png";

import appDevelopment from "../../assets/icons/tech/top-mobile-app-development-company.svg";
import webDevelopmet from "../../assets/icons/tech/web-development-services.svg";
import aiDevelopmet from "../../assets/icons/tech/generative-ai-development-services.svg";
import backendDevelopment from "../../assets/icons/tech/backend-development-company.svg";
import MVPDevelopment from "../../assets/icons/tech/bespoke-mvp-development-services.svg";

import applicationSetting from "../../assets/img/application-settings.png";

import IconAndroid from "../../assets/icons/tech/android.svg";
import IconApple from "../../assets/icons/tech/apple.svg";
import IconFlutter from "../../assets/icons/tech/flutter.svg";
import IconReactNative from "../../assets/icons/tech/react-native.svg";
import IconReact from "../../assets/icons/tech/reactjs.svg";
import IconAngular from "../../assets/icons/tech/angularjs.svg";
import IconVue from "../../assets/icons/tech/vuejs.svg";
import IconNode from "../../assets/icons/tech/nodejs.svg";
import IconGPT from "../../assets/icons/tech/gpt.svg";
import IconGemini from "../../assets/icons/tech/gemini.svg";
import IconLLMA from "../../assets/icons/tech/llama.svg";
import IconExpress from "../../assets/icons/tech/express.svg";
import IconNest from "../../assets/icons/tech/nestjs.svg";
import IconGraphQL from "../../assets/icons/tech/graphql.svg";
import IconElasticSearch from "../../assets/icons/tech/elasticsearch.svg";

function Services() {
  return (
    <>
      <section className="wrapper bg-light" id="services">
        <div className="container pt-14 pt-md-16">
          <div className="row text-center">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 className="fs-16 text-uppercase text-muted mb-3">
                What We Do?
              </h2>
              <h3 className="display-4 mb-10 px-xl-10">
                The service we offer is specifically designed to meet your
                needs.
              </h3>
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
          <div className="position-relative">
            <div
              className="shape rounded-circle bg-soft-blue rellax w-16 h-16"
              // data-rellax-speed={1}
              style={{ bottom: "-0.5rem", right: "-2.2rem", zIndex: 0 }}
            />
            <div
              className="shape bg-dot primary rellax w-16 h-17"
              // data-rellax-speed={1}
              style={{ top: "-0.5rem", left: "-2.5rem", zIndex: 0 }}
            />
            <div
              className="row gx-md- gy-5 text-center services"
              // data-aos="zoom-in"
            >
              <div className="col-md-6">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={webDevelopmet}
                      className="text-yellow mb-3"
                      alt="Web App Development at DoBeDo Soft"
                    />
                    <h4>Web Applications</h4>
                    <p className="mb-2">
                      Build your Web application with cutting-edge technologies
                      and battle-tested tools.
                    </p>
                    <img
                      src={IconReact}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="React JS"
                    />
                    <img
                      src={IconAngular}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="Andgular JS"
                    />
                    <img
                      src={IconVue}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="Vue JS"
                    />
                    <img
                      src={IconNode}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="Node JS"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={backendDevelopment}
                      className="text-yellow mb-3"
                      alt="Backend Development at DoBeDo Soft"
                    />
                    <h4>Backend API Dev.</h4>
                    <p className="mb-2">
                      Build extensible back-end solutions for mobile, web and
                      IOTs that scales with growing business needs.
                    </p>
                    <img
                      src={IconNode}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="Node JS"
                    />
                    <img
                      src={IconExpress}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="Express JS"
                    />
                    <img
                      src={IconNest}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="Nest JS"
                    />
                    <img
                      src={IconGraphQL}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="GraphQL"
                    />
                    <img
                      src={IconElasticSearch}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="Elastic Search"
                    />
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              <div className="col-md-6">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={appDevelopment}
                      className="text-yellow mb-3"
                      alt="Mobile App Development at DoBeDo Soft"
                    />
                    <h4>Mobile Applications</h4>
                    <p className="mb-2">
                      We provide you with mobile app development services to
                      meet the demands of a constantly changing industry.
                    </p>
                    <img
                      src={IconFlutter}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="Flutter"
                    />
                    <img
                      src={IconAndroid}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="Android"
                    />
                    <img
                      src={IconApple}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="iOS"
                    />
                    <img
                      src={IconReactNative}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="React Native"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={MVPDevelopment}
                      className="text-blue mb-3"
                      alt=""
                    />
                    <h4>MVP Development Services</h4>
                    <p className="mb-2">
                      Get end to end software development services for startups
                      and enterprises.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={aiDevelopmet}
                      className="text-yellow mb-3"
                      alt="Web App Development at DoBeDo Soft"
                    />
                    <h4>Generative AI Development</h4>
                    <p className="mb-2">
                      Seek highly innovative solutions leveraging the power of AI with DOBEDO SOFT. Our generative AI development services enhance user experiences, automate tasks, and offer incredible features that amaze you.
                    </p>
                    <img
                      src={IconGPT}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="Chat GPT"
                    />
                    <img
                      src={IconGemini}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="Google Gemini"
                    />
                    <img
                      src={IconLLMA}
                      className="icon-svg icon-svg-sm text-yellow m-1 p-1"
                      alt="Large Language Model Meta AI"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
