import React from "react";
import about_7 from "../../assets/img/photos/about7.jpg";
import target from "../../assets/img/icons/lineal/target.svg";
import award_2 from "../../assets/img/icons/lineal/award-2.svg";

const About = () => {
  return (
    <div id="about">
      <section id="snippet-1" className="wrapper bg-light wrapper-border">
        {/* pt-15 pt-md-17 */}
        <div className="container  pb-13 pb-md-15 pt-5 pt-md-17">
          <div
            className="row gx-lg-8 gx-xl-12 gy-10 align-items-center"
            // data-aos="zoom-in"
          >
            <div className="col-md-8 col-lg-6 col-xl-5 order-lg-2 position-relative">
              <div
                className="shape bg-soft-primary rounded-circle rellax w-20 h-20"
                data-rellax-speed={1}
                style={{
                  top: "-2rem",
                  right: "-1.9rem",
                  transform: "translate3d(0px, 4px, 0px)",
                }}
              />
              <figure className="rounded">
                <img src={about_7} alt="" />
              </figure>
            </div>
            {/*/column */}
            <div className="col-lg-6">
              <h2 className="display-4 mb-3">Who Are We?</h2>
              <p className="lead fs-lg">
                Dobedo Soft: Your Trusted Partner for Cutting-Edge Software
                Development Services, from Web Development to App Development
                and Beyond
              </p>
              <p className="mb-6">
                Dobedo Soft is a leading provider of software development
                services, specializing in web development, mobile app
                development, and more. Our team of experienced developers is
                committed to delivering cutting-edge solutions that cater to
                your unique business needs and requirements.
              </p>
              <div className="row gx-xl-10 gy-6">
                <div className="col-md-6">
                  <div className="d-flex flex-row">
                    <div>
                      <img
                        src={target}
                        className="icon-svg icon-svg-sm me-4"
                        alt=""
                      />
                    </div>
                    <div>
                      <h4 className="mb-1">Our Mission</h4>
                      <p className="mb-0">
                        Our mission is to provide innovative and customized
                        software solutions that drive our clients' success.
                      </p>
                    </div>
                  </div>
                </div>
                {/*/column */}
                <div className="col-md-6">
                  <div className="d-flex flex-row">
                    <div>
                      <img
                        src={award_2}
                        className="icon-svg icon-svg-sm me-4"
                        alt=""
                      />
                    </div>
                    <div>
                      <h4 className="mb-1">Our Values</h4>
                      <p className="mb-0">
                        Our values define us and guide us towards delivering
                        exceptional results for our clients.
                      </p>
                    </div>
                  </div>
                </div>
                {/*/column */}
              </div>
              {/*/.row */}
            </div>
            {/*/column */}
          </div>
          {/*/.row */}
        </div>
      </section>
    </div>
  );
};

export default About;
