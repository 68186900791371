import React from "react";
import dobedo_logo from "../../assets/img/DOBEDO_LOGO.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Footer() {
  let location = useLocation();
  const navigate = useNavigate();
  const handlePages = () => {
    const page = location.pathname;
    if (page !== "/") {
      navigate("/");
    }
  };
  return (
    <>
      <section className="wrapper bg-light angled upper-end lower-start">
        {/* /.container */}
      </section>

      <footer className="bg-dark text-inverse bg-dark-footer">
        <div className="container pt-17 pt-md-19 pb-13 pb-md-15">
          <div className="row gy-6 gy-lg-0">
            <div className="col-md-4 col-lg-3">
              <div className="widget">
                <img
                  className="mb-4"
                  src={dobedo_logo}
                  // srcSet="./assets/img/logo-light@2x.png 2x"
                  alt=""
                  style={{ width: "115px", height: "36.2px" }}
                />
                <p className="mb-4">
                  © {new Date().getFullYear()} Dobedo Soft.{" "}
                  <br className="d-none d-lg-block" />
                  All rights reserved.
                </p>
                <nav className="nav social social-white">
                  <a
                    href="https://in.linkedin.com/company/dobedo-soft"
                    target="_blank"
                  >
                    <i class="fa-brands fa-linkedin"></i>
                  </a>
                  <a href="https://www.facebook.com/people/Dobedo-Soft/100088378183383/">
                    <i class="fa-brands fa-square-facebook"></i>
                  </a>

                  <a
                    href="https://www.instagram.com/dobedosoft/"
                    target="_blank"
                  >
                    <i class="fa-brands fa-square-instagram"></i>
                  </a>
                </nav>
                {/* /.social */}
              </div>
              {/* /.widget */}
            </div>
            {/* /column */}
            <div className="col-md-4 col-lg-3">
              <div className="widget">
                <h4 className="widget-title text-white mb-3">Get in Touch</h4>
                <a href="cdn-cgi/l/email-protection.html#8ba8">
                  <span
                    className="__cf_email__"
                    data-cfemail="0a63646c654a6f676b636624696567"
                  >
                    <a href="mailto:hello@dobedosoft.com">
                      hello@dobedosoft.com
                    </a>
                  </span>
                </a>
                <br /> <a href="tel:+916352746259"> +91 6352 746259</a>
              </div>
              {/* /.widget */}
            </div>
            {/* /column */}
            <div className="col-md-4 col-lg-3">
              <div className="widget">
                <h4 className="widget-title text-white mb-3">Learn More</h4>
                <ul className="list-unstyled  mb-0">
                  <li>
                    <a href="#about" onClick={handlePages}>
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="#services" onClick={handlePages}>
                      Services
                    </a>
                  </li>
                  <li>
                    <Link to="/career">Career</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  {/* <li>
                    <a href="#">Terms of Use</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li> */}
                </ul>
              </div>
              {/* /.widget */}
            </div>
            {/* /column */}
            <div className="col-md-12 col-lg-3">
              {/* <div className="widget">
                <h4 className="widget-title text-white mb-3">Our Newsletter</h4>
                <p className="mb-5">
                  Subscribe to our newsletter to get our news &amp; deals
                  delivered to you.
                </p>
                <div className="newsletter-wrapper">
                  <div id="mc_embed_signup2">
                    <form
                      action="https://elemisfreebies.us20.list-manage.com/subscribe/post?u=aa4947f70a475ce162057838d&id=b49ef47a9a"
                      method="post"
                      id="mc-embedded-subscribe-form2"
                      name="mc-embedded-subscribe-form"
                      className="validate dark-fields"
                      target="_blank"
                      noValidate=""
                    >
                      <div id="mc_embed_signup_scroll2">
                        <div className="mc-field-group input-group form-floating">
                          <input
                            type="email"
                            defaultValue=""
                            name="EMAIL"
                            className="required email form-control"
                            placeholder="Email Address"
                            id="mce-EMAIL2"
                          />
                          <label htmlFor="mce-EMAIL2">Email Address</label>
                          <input
                            type="submit"
                            defaultValue="Join"
                            name="subscribe"
                            id="mc-embedded-subscribe2"
                            className="btn btn-primary "
                          />
                        </div>
                        <div id="mce-responses2" className="clear">
                          <div
                            className="response"
                            id="mce-error-response2"
                            style={{ display: "none" }}
                          />
                          <div
                            className="response"
                            id="mce-success-response2"
                            style={{ display: "none" }}
                          />
                        </div>{" "}
                        <div
                          style={{ position: "absolute", left: "-5000px" }}
                          aria-hidden="true"
                        >
                          <input
                            type="text"
                            name="b_ddc180777a163e0f9f66ee014_4b1bcfa0bc"
                            tabIndex={-1}
                            defaultValue=""
                          />
                        </div>
                        <div className="clear" />
                      </div>
                    </form>
                  </div>
                </div>
              </div> */}
              <div className="widget">
                <h4 className="widget-title text-white mb-3">We Provide</h4>
                <ul className="list-unstyled  mb-0">
                  <li>
                    <a href="#">Web Development</a>
                  </li>
                  <li>
                    <a href="#">Application Development</a>
                  </li>
                  <li>
                    <a href="#">MVP Development</a>
                  </li>
                  <li>
                    <a href="#">Backend API Dev.</a>
                  </li>
                  <li>
                    <a href="#">SAAS Development</a>
                  </li>
                  <li>
                    <a href="#">Product Engineering</a>
                  </li>
                </ul>
              </div>

              {/* /.widget */}
            </div>
            {/* /column */}
          </div>
          {/*/.row */}
        </div>
        {/* /.container */}
      </footer>
    </>
  );
}

export default Footer;
