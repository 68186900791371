import Footer from "components/footer";
import Navbar from "components/navbar";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import target from "assets/img/target.png";
import videocall from "assets/img/videoconference.png";
import wallet from "assets/img/wallet.png";
import alarm from "assets/img/clock.png";
import { collection, addDoc, getDocs, where, query } from "firebase/firestore";
import { fireStore } from "../firebase";
import { useAlert } from "react-alert";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { uuid } from "utils/uuid";

function Career() {
  const alert = useAlert();
  const fileRef = useRef();
  const [user, setUser] = useState({
    email: "",
    name: "",
    message: "",
    portfolio: "",
    mobile: "",
    linkedin: "",
    resume: "",
  });

  const [error, setError] = useState({
    email: "",
    name: "",
    mobile: "",
    resume: "",
  });

  const storage = getStorage();

  const [pdf, setPdf] = useState("");
  const upload = (file) => {
    const storageRef = ref(storage, file?.name + uuid());
    // const file = e.target.files[0];
    return new Promise((res, rej) => {
      var fileSize = file.size / 1024 / 1024;
      if (fileSize > 1) {
        // alert.show("Submitted successfully!", {
        //   type: "success",
        // });
        setError({ ...error, resume: "Please provide file less than 1 mb!" });
      }
      if (pdf == null) return;
      // storage
      //   .ref(`/images/${image.name}`)
      //   .put(image)
      //   .on("state_changed", alert("success"), alert);
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, file).then((snapshot) => {
        // console.log("Uploaded a blob or file!");
        // console.log(snapshot);
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          // console.log("Download link to your file: ", downloadURL);
          setUser({ ...user, resume: downloadURL });
          res(downloadURL);
        });
      });
    });
  };

  const handleChange = (e) => {
    handleValidationOnChange(e.target.name, e.target.value);
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleValidationOnChange = (key, value) => {
    if (value !== "") {
      // errors[key] = "";
      setError({ ...error, [key]: "" });
    }
  };

  const handleValidation = (data) => {
    const errors = {};
    let err = false;
    if (user.email === "" || user.name === "" || user.mobile === "") {
      errors.email = user.email === "" ? "Email is required" : "";
      errors.name = user.name === "" ? "Name is required" : "";
      errors.mobile = user.mobile === "" ? "Contact is required" : "";
      setError(errors);
      err = true;
    }
    if (data.email) {
      const pattern =
        // eslint-disable-next-line
        /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
      const result = pattern.test(data.email);
      if (result === false) {
        errors.email = "Invalid Email";
        err = true;
      }
    }

    if (data.mobile) {
      // eslint-disable-next-line
      const pattern =
        // eslint-disable-next-line
        /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
      if (pattern.test(data.mobile)) {
        if (data.mobile.length !== 10) {
          errors.mobile = "Please put 10  digit mobile number";
          err = true;
        }
      } else {
        errors.mobile = "Invalid mobile number";
        err = true;
      }
    }

    setError(errors);
    return err;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const dataUrl = await upload(pdf);
    user.resume = dataUrl;
    // setUser({ ...user, resume: dataUrl });
    if (!handleValidation(user)) {
      try {
        const docRef = await addDoc(collection(fireStore, "career"), {
          // id: uuid(),
          ...user,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });
        if (docRef.id) {
          // alert("Your Details submitted successfully!");
          alert.show("Submitted successfully!", {
            type: "success",
          });
        }
        setUser({
          name: "",
          email: "",
          message: "",
          mobile: "",
          linkedin: "",
          portfolio: "",
          resume: "",
        });
        fileRef.current.value = "";
        // setAppInfo("");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  return (
    <div>
      <Navbar />
      <section
        className="wrapper bg-dark angled lower-start"
        style={{ backgroundColor: "#1D1E25!important" }}
      >
        <div className="row pr-0 mr-0">
          <div className="col-lg-8 mx-auto">
            <div className="text-white get-in-touch"
            //  data-aos="zoom-in"
             >
              <h3 className="display-4 text-white">Join Our Team</h3>
              <h5 class="text-secondary">
                Join our team to help shape the future of development.
              </h5>
            </div>
            {/* <h1 class="fs-15 text-uppercase text-muted mb-3">Join Our Team</h1>*/}

            {/* /nav */}
          </div>
          {/* /column */}
        </div>

        {/* /.container */}
      </section>

      <section className="wrapper bg-light">
        <div className="container pb-15 pb-md-17">
          <div className="row gx-md-5 gy-5 mt-n18 mt-md-n19 jobs">
            <div className="col-md-6 col-xl-3">
              <div className="card lift shadow-lg">
                <div className="card-body">
                  <img
                    src={target}
                    className="icon-svg icon-svg-sm solid-mono text-primary mb-3"
                    alt=""
                  />
                  <h4>Career Growth</h4>
                  <p className="mb-0">
                    We're here to help you take your career to the next level.
                  </p>
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/*/column */}
            <div className="col-md-6 col-xl-3">
              <div className="card lift shadow-lg">
                <div className="card-body">
                  <img
                    src={videocall}
                    className="icon-svg icon-svg-sm solid-mono text-primary mb-3"
                    alt=""
                  />
                  <h4>Work From Anywhere</h4>
                  <p className="mb-0">
                    Work from anywhere with our flexible remote jobs. Achieve
                    work-life balance and take control of your career.
                  </p>
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/*/column */}
            <div className="col-md-6 col-xl-3">
              <div className="card lift shadow-lg">
                <div className="card-body">
                  <img
                    src={wallet}
                    className="icon-svg icon-svg-sm solid-mono text-primary mb-3"
                    alt=""
                  />
                  <h4>Smart Salary</h4>
                  <p className="mb-0">
                    Get the salary you deserve with Smart Salary. Maximize your
                    earning potential and advance your career.
                  </p>
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/*/column */}
            <div className="col-md-6 col-xl-3">
              <div className="card lift shadow-lg">
                <div className="card-body">
                  <img
                    src={alarm}
                    className="icon-svg icon-svg-sm solid-mono text-primary mb-3"
                    alt=""
                  />
                  <h4>Flexible Hours</h4>
                  <p className="mb-0">
                    Work on your own terms with Flexible Hours. Find jobs that
                    fit your schedule and achieve work-life balance.
                  </p>
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/*/column */}
          </div>
          {/*/.row */}
        </div>
        {/* /.container */}
      </section>

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16 pt-10 pt-md-10">
          <div className="row text-center" 
          // data-aos="zoom-in"
          >
            <div className="col-xl-10 mx-auto">
              <h2 className="fs-15 text-uppercase text-muted mb-3">
                Job Positions
              </h2>
              <h3 className="display-4 mb-10 px-xxl-15">
                We’re always searching for amazing people to join our team. Take
                a look at our current openings.
              </h3>
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
          <div className="row gy-6">
            <div className="col-md-6 col-lg-4">
              <a
                href="#"
                className="card shadow-lg lift h-100"
                // data-aos="zoom-in"
              >
                <div className="card-body p-5 d-flex flex-row">
                  <div>
                    <span className="avatar bg-red text-white w-11 h-11 fs-20 me-4">
                      MD
                    </span>
                  </div>
                  <div>
                    <span className="badge bg-pale-blue text-blue rounded py-1 mb-2">
                      Full Time
                    </span>
                    <h4 className="mb-1">MERN Stack Developer</h4>
                    <p className="mb-0 text-body">3 years+ experience</p>
                  </div>
                </div>
              </a>
            </div>
            {/*/column */}
            <div className="col-md-6 col-lg-4">
              <a
                href="#"
                className="card shadow-lg lift h-100"
                // data-aos="zoom-in"
              >
                <div className="card-body p-5 d-flex flex-row">
                  <div>
                    <span className="avatar bg-green text-white w-11 h-11 fs-20 me-4">
                      AD
                    </span>
                  </div>
                  <div>
                    <span className="badge bg-pale-blue text-blue rounded py-1 mb-2">
                      Full Time
                    </span>
                    <h4 className="mb-1">Android Developer</h4>
                    <p className="mb-0 text-body">3 years+ experience</p>
                  </div>
                </div>
              </a>
            </div>
            {/*/column */}
            <div className="col-md-6 col-lg-4">
              <a
                href="#"
                className="card shadow-lg lift h-100"
                // data-aos="zoom-in"
              >
                <div className="card-body p-5 d-flex flex-row">
                  <div>
                    <span className="avatar bg-yellow text-white w-11 h-11 fs-20 me-4">
                      FD
                    </span>
                  </div>
                  <div>
                    <span className="badge bg-pale-blue text-blue rounded py-1 mb-2">
                      Full Time
                    </span>
                    <h4 className="mb-1">Flutter Developer</h4>
                    <p className="mb-0 text-body">3 years+ experience</p>
                  </div>
                </div>
              </a>
            </div>
            {/*/column */}
          </div>
          {/*/.row */}
          {/* <div className="row mt-11" data-aos="zoom-in">
            <div className="col-lg-6 col-xl-5 mx-auto text-center">
              <h2 className="display-6 mb-3">Can't find the right position?</h2>
              <p className="lead mb-5 px-md-16 px-lg-3">
                Create great products with us! Let’s write some beautiful code
                and make smart designs. This is what we’re crazy about, and
                we’re looking for the ones who feel the same.
              </p>
              <Link
                to="/contact-us"
                className="btn btn-primary contact-us-button"
              >
                Apply Now
              </Link>
            </div>
          </div> */}
          <div className="row pt-15">
            <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
              <h2 className="display-4 mb-3 text-center">Apply For Job</h2>
              <p className="lead text-center mb-10">
                Reach out to us from our job form and we will get back to you
                shortly.
              </p>
              <form
                className="contact-form needs-validation contactForm"
                method="post"
                action="https://sandbox.elemisthemes.com/assets/php/contact.php"
                noValidate=""
                onSubmit={onSubmit}
              >
                <div className="messages" />
                <div className="row gx-4">
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="form_name"
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Jane"
                        required=""
                        value={user.name}
                        onChange={handleChange}
                      />
                      <label htmlFor="form_name">Full Name *</label>
                      <div className="valid-feedback"> Looks good! </div>
                      {error.name && (
                        <div className="invalid-feedback">
                          {" "}
                          Please enter your first name.{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="form_email"
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="jane.doe@example.com"
                        required=""
                        value={user.email}
                        onChange={handleChange}
                      />
                      <label htmlFor="form_email">Email *</label>
                      <div className="valid-feedback"> Looks good! </div>
                      {error.email && (
                        <div className="invalid-feedback">
                          {" "}
                          Please provide a valid email address.{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="form_email"
                        type="tel"
                        name="mobile"
                        className="form-control"
                        placeholder="+91 9999 455455"
                        required=""
                        value={user.mobile}
                        onChange={handleChange}
                      />
                      <label htmlFor="form_email">Contact *</label>
                      <div className="valid-feedback"> Looks good! </div>
                      {error.mobile && (
                        <div className="invalid-feedback">
                          {" "}
                          Please provide a valid contact number.{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="form_email"
                        type="text"
                        name="linkedin"
                        className="form-control"
                        placeholder="linkedin.com"
                        required=""
                        value={user.linkedin}
                        onChange={handleChange}
                      />
                      <label htmlFor="form_email">Linkedin Profile</label>
                      <div className="valid-feedback"> Looks good! </div>
                      {/* {error.mobile && (
                        <div className="invalid-feedback">
                          {" "}
                          Please provide a valid contact number.{" "}
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-floating mb-4">
                      <input
                        id="form_email"
                        type="text"
                        name="portfolio"
                        className="form-control"
                        placeholder="portfolio.com"
                        required=""
                        value={user.portfolio}
                        onChange={handleChange}
                      />
                      <label htmlFor="form_email">Portfolio URL</label>
                      <div className="valid-feedback"> Looks good! </div>
                      {/* {error.mobile && (
                        <div className="invalid-feedback">
                          {" "}
                          Please provide a valid contact number.{" "}
                        </div>
                      )} */}
                    </div>
                  </div>
                  {/* /column */}
                  <div className="col-12">
                    <div className="form-floating mb-4">
                      <textarea
                        id="form_message"
                        name="message"
                        className="form-control"
                        placeholder="Your message"
                        style={{ height: 150 }}
                        required=""
                        defaultValue={""}
                        value={user.message}
                        onChange={handleChange}
                      />
                      <label htmlFor="form_message">Message </label>
                      <div className="valid-feedback"> Looks good! </div>
                      {/* {error.message && (
                        <div className="invalid-feedback">
                          {" "}
                          Please enter your messsage.{" "}
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-4">
                      <input
                        id="form_name"
                        type="file"
                        name="resumeFile"
                        className="form-control file-control"
                        placeholder="Resume File"
                        required=""
                        // value={user.resume}
                        onChange={(e) => {
                          setPdf(e.target.files[0]);
                        }}
                        accept=".doc, .docx, .txt, .pdf"
                        ref={fileRef}
                      />
                      {/* <label htmlFor="form_name">Upload Resume </label> */}
                      <div className="valid-feedback d-block text-muted">
                        {" "}
                        ! PDF file must be less than 1MB.{" "}
                      </div>
                      {/* {error.name && ( */}
                      {error?.resume && (
                        <div className="invalid-feedback">
                          {" "}
                          ! PDF file must be less than 1MB.{" "}
                        </div>
                      )}
                      {/* )} */}
                    </div>
                  </div>
                  {/* /column */}
                  <div className="col-12 text-center">
                    <input
                      type="submit"
                      className="btn btn-primary btn-send mb-3 contact-us-button"
                      defaultValue="Send message"
                    />
                    <p className="text-muted">
                      <strong>*</strong> These fields are required.
                    </p>
                  </div>
                  {/* /column */}
                </div>
                {/* /.row */}
              </form>
              {/* /form */}
            </div>
            {/* /column */}
          </div>
        </div>
        {/* /.container */}
      </section>

      <Footer />
    </div>
  );
}

export default Career;
