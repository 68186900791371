import "./App.css";
import { BrowserRouter } from "react-router-dom";
import CustomRoutes from "./routes";
import "./assets/img/favicon.png";
import "./assets/css/plugins.css";
import "./assets/css/style.css";
import "./assets/css/colors/aqua.css";
import "./assets/css/fonts/thicccboi.css";
import "./assets/styles/global.css";
import { useLocation } from "react-router";
import { useEffect } from "react";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <CustomRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
